import React from "react"
import styles from "./style.module.css";
import { Member } from "../../crud/member/create/type";

type Props = {
  member: Member,
  onClick?: ()=>void
}

export const Template = (props: Props) => {
  return (
    <div className={styles.profile} onClick={props.onClick}>
      <img src={props.member.image} />
      <p>{props.member.role}</p>
      <p style={{ fontWeight: "bold" }}>{props.member.name}</p>
    </div>
  )
}