import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Contact } from "./contact";
import { Consulting } from "./consulting";
import { Home } from "./home";
import { Members } from "./members";
import { Company } from "./company";
import { ScrollTop } from "../share/scrollTop";
import { Header, Footer } from "../share";
import { Admin } from "./admin";
import { QueryClientProvider, QueryClient } from "react-query";
import { NewsPage } from "./news";
import { Article } from "../share/article";
import { AchievementList } from "./achievement";

export const Pages = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true,
      },
    }
  })

  const routers = [
    { path: "contact", element: <Contact /> },
    { path: "consulting", element: <Consulting /> },
    { path: "members", element: <Members /> },
    { path: "company", element: <Company /> },
    { path: "news/:id", element: <NewsPage /> },
    { path: "admin", element: <Admin /> },
    { path: "achievements", element: <AchievementList /> },
    { path: "*", element: <Home /> },
  ]

  return (
    <>
    <QueryClientProvider client={queryClient}>
    <Suspense fallback={<></>}>
      <Router>
      <Header />
        <ScrollTop />
        <Routes>
          {
            routers.map(route => (
              <Route path={route.path} element={<div className="fadeUp">
                {route.element}
                </div>
      }/>
            ))
          }
        </Routes>
      </Router>
      <Footer/>
      </Suspense>
      </QueryClientProvider>
    </>
  )
}