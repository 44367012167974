import React, { useState, ChangeEvent } from "react";
import { PreviewContent, contentTypes, ContentType } from "../../../pages/type";
import { Template as Article } from "../../../share/article";
import { Modal } from "../../../share/modal";
import Button from '@mui/material/Button';
import { NewsList, Template as NewsTemplate } from "../../../pages/news";
import { Achievement } from "./type";
import { AchievementList, Template as AchievementTemplate } from "../../../pages/achievement";

export const CreateAchievement = () => {
  const handleCreateAchievement = async () => {
    fetch('https://v4ikt55t53.execute-api.ap-northeast-1.amazonaws.com/achievement', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(currentParams)
    }).then(() => window.alert("挿入しました")).catch(() => window.alert("失敗しました"));
  }

  const [currentParams, setCurerntParams] = useState<(Omit<Achievement, 'uid' | 'datetime'>)>({
    title: "",
    client: "",
    country: "",
    about: ""
  })

  const [isOpenContents, setIsOpenContents] = useState(false);

  const handleOnChangeParams = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target
    setCurerntParams(prev => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <>
      <div style={{ marginBottom: 30, display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }} >
        <div>
        タイトル: <input name="title" placeholder="タイトル" onChange={handleOnChangeParams} required/>
        </div>
        <div>
        支援企業: <input name="client" placeholder="支援企業" onChange={handleOnChangeParams} required/>
        </div>
        <div>
        対象国: <input name="country" placeholder="対象国" onChange={handleOnChangeParams} required/>
        </div>
        <div>
        概要: <input name="about" placeholder="概要" onChange={handleOnChangeParams} required/>
        </div>
        <Button variant="outlined" onClick={() => setIsOpenContents(true)}>プレビューを確認する</Button>
      </div>

      <Modal isOpen={isOpenContents} onClose={() => setIsOpenContents(false)}>
        <AchievementList>
        <AchievementTemplate achievement={{ ...currentParams, uid: "", datetime: "" }}/>
        </AchievementList>
        {Object.values(currentParams).every(value => value) ? <div style={{ display:"flex", justifyContent: "center" }}><Button variant="contained" onClick={handleCreateAchievement}>この内容で保存する</Button> </div> : null }

      </Modal>
    </>

  )
}
