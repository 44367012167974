export const contentTypes = [
  "header",
  "text",
  "image",
  "br"
] as const

export type ContentType = typeof contentTypes[number]

export type Content = {
  type: Exclude<ContentType, "br">,
  value: string
} | {
  type: "br"
}

type PreviewContentBase = Omit<Content, 'value'>;

export type PreviewContent = PreviewContentBase & ({ type: 'image', value: File } | { type: Exclude<ContentType, 'image' | 'br'>, value: string } | { type: 'br' });