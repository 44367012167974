import React from "react";
import { Navigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  when: boolean;
  path: string;
}

export const WrapperRedirect = ({ children, when, path }: Props) => {
  return (
    <React.Fragment>{when ? <Navigate to={path} /> : children}</React.Fragment>
  );
};
