import React from "react";
import styles from "./style.module.css";
export const Footer = () => { 
  return (
    <footer className={styles.footerContainer}>
      <img className={styles.footerLogo} src={`${process.env.PUBLIC_URL}/images/logo.png`}/>
      <div style={{ width:"100%", display:"flex",justifyContent:"space-between"}}>
      <nav className={styles.footerNav}>
        <a href="/services" className={`${styles.footerNavLink}`}>事業紹介</a>
        <a href="/team" className={`${styles.footerNavLink}`}>プロフェッショナル紹介</a>
        <a href="/experience" className={`${styles.footerNavLink}`}>支援実績</a>
        <a href="/contact" className={`${styles.footerNavLink}`}>お問い合わせ</a>
      </nav>
      <p style={{ color: "gray" }}>Copyright © Historie Inc.</p>
      </div>
      
    </footer>
  )
}