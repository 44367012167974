import React from "react"
import styles from "./style.module.css";
import { Footer } from "..";
import { Content } from "../../pages/type";

type Props = {
  contents: Content[] 
}
export const Template = (props: Props) => {
  return props.contents.length > 0 ? (
    <>
    <div className={[styles.contents].join(" ")}>
      {props.contents.map(content => {
        if(content.type === "header") return (
          <div className={styles.mainHeader}>
            <h2>{content.value}</h2>
          </div>
        )
        if(content.type === "text") return (
          <p>
            {content.value}
          </p>
        )
        if(content.type === "image") return <img src={content.value} style={{ width: "100%" }}/>
        if(content.type === "br") return <br/>
      })}
    </div>
    </>
  ) : null
}