import React, { useState, ChangeEvent, FormEvent } from "react"
import styles from "./style.module.css";
import { Footer, HeaderImage } from "../../share";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    body: '',
    purpose: ''
  });

  // handleChange関数
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // handleSubmit関数
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // デフォルトのフォーム送信を防ぐ
    try {
      const response = await fetch('https://dncrgcutxw64lgklzcj3cg6fqq0jgllb.lambda-url.ap-northeast-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) throw new Error('Something went wrong');
      // レスポンスを処理する（例：送信成功メッセージを表示）
      alert('送信に成功しました！');
    } catch (error) {
      // エラー処理を行う
    }
  };

  const handleOnChangeForCheckbox = (purpose: string) => {
    setFormData(prevState => ({
      ...prevState,
      purpose
    }));
  };

  return (
    <>
    <HeaderImage src={`${process.env.PUBLIC_URL}/images/image8.png`} titleEn={"Contact"} titleJp={"お問い合わせ"} />
    <div className={[styles.container].join(" ")}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <label htmlFor="name" className={styles.label}>お名前</label>
          <input onChange={handleChange} type="text" id="name" name="name" className={styles.input} required placeholder="お名前を入力してください"/>
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="email" className={styles.label}>メールアドレス</label>
          <input onChange={handleChange} type="email" id="email" name="email" className={styles.input} required placeholder="sample@xxx.com"/>
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="company" className={styles.label}>会社・組織名</label>
          <input onChange={handleChange} type="text" id="company" name="company" className={styles.input} required placeholder="会社・組織名を入力してください"/>
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="subject" className={styles.label}>お問い合わせ</label>
          <div className={styles.checkboxList}>
            <div className={styles.checkboxItem}>
              <input type="checkbox" onChange={() => handleOnChangeForCheckbox("無料相談を利用したい")}/>
              無料相談を利用したい
            </div>
            <div className={styles.checkboxItem} onChange={() => handleOnChangeForCheckbox("お見積もりを依頼したい")}>
              <input type="checkbox" />
              お見積もりを依頼したい
            </div>
            <div className={styles.checkboxItem} onChange={() => handleOnChangeForCheckbox("その他")}>
              <input type="checkbox" />
              その他
            </div>
          </div>
          {/* <input onChange={handleChange} type="text" id="subject" name="subject" className={styles.input} /> */}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="body" className={styles.label}>本文</label>
          <textarea onChange={handleChange} id="body" name="body" className={styles.textarea} required placeholder="「市場調査について、1時間の無料オンライン相談を利用したい」など"/>
        </div>
        <button type="submit" className={styles.submit}>
          <span>フォームを送信</span>
        </button>
      </form>
    </div>
    </>
  )
}