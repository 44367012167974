import React, { ReactNode } from "react";
import styles from "./style.module.css";

type Props = {
  isOpen: boolean;
  onClose: () => void,
  children: ReactNode
}

export const Modal = (props: Props) => {
  if (!props.isOpen) return null;

  return (
    <div className={`${styles["modal-overlay"]}`} onClick={props.onClose}>
      <div className={styles["modal-container"]} onClick={e => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  );
};
