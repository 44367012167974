import { News } from "./type";
import React, { useState, ChangeEvent } from "react";
import { PreviewContent, contentTypes, ContentType } from "../../../pages/type";
import { Template as Article } from "../../../share/article";
import { Modal } from "../../../share/modal";
import Button from '@mui/material/Button';
import { NewsList, Template as NewsTemplate } from "../../../pages/news";
function getExtensionFromMimeType(mimeType: string) {
  switch(mimeType){
    case 'image/jpeg':
      return '.jpg'
    case 'image/png':
      return '.jpg'
    default:
      throw Error("画像はjpegとpngのみです")
  }
}

export const CreateNews = () => {
  const handleCreateNews = async () => {
    const contents = await Promise.all(curerntContents.map(item =>{ 
      return new Promise(resolve => {
        if(item.type !== "image") return resolve(item)
        uploadFile(item.value).then((res)=>{res.text().then((uid) =>{
          console.log(uid)
          return resolve({
            ...item,value: `https://historie-resources.s3.ap-northeast-1.amazonaws.com/${uid}`
          })
        })})
      })
    }))

    fetch('https://v4ikt55t53.execute-api.ap-northeast-1.amazonaws.com/news', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contents: contents
      })
    }).then(() => window.alert("挿入しました")).catch(() => window.alert("失敗しました"));
  }

  const uploadFile = (file: File) => {
    const extension = getExtensionFromMimeType(file.type)
    const options = {
      method: 'POST',
      body: file
    };

    return fetch(`https://lgylhafcpt6kqe4l27j7mz6bdy0hcuuu.lambda-url.ap-northeast-1.on.aws/?extension=${extension}`, options);
  }

  const [curerntContents, setCurerntContents] = useState<(PreviewContent[])>([])
  const [currentSelectType, setCurrentSelectType] = useState<(ContentType)>(contentTypes[0])
  const [curerntSelectFile, setCurerntSelectFile] = useState<File | null>(null)
  const [curerntSelectText, setCurerntSelectText] = useState<string>("")

  const previewContents = curerntContents.map(item =>{
    if(item.type !== 'image') return item
    return {
      ...item,
      value: URL.createObjectURL(item.value)
    }
  })

  const [isOpenContents, setIsOpenContents] = useState(false);

  return (
    <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
        <h2>ニュース本文</h2>
              <div>
              追加type: <select onChange={(e) => setCurrentSelectType(e.target.value as ContentType)}>
              {contentTypes.map(contentType => {
                return <option>{contentType}</option>
              })}
            </select>
              </div>
            {
              (() => {
                if(currentSelectType === "image") return <div>添付画像: <input name="image" type="file" onChange={(e) => setCurerntSelectFile(prev => e.target.files ? e.target.files[0] : prev)} required/></div>
                if(currentSelectType === "br") return null
                return <div>内容: <textarea onChange={(e) => {setCurerntSelectText(e.target.value)}} /></div>
              })()
            }
            <Button variant="outlined" onClick={(e) => {
            e.stopPropagation()
            setCurerntContents(prev => {
              if(currentSelectType === "image") {
                if(curerntSelectFile) return [...prev, { type: currentSelectType, value: curerntSelectFile }]
                return prev
              }
              if(currentSelectType === "br") return [...prev, { type: currentSelectType }]
              return [...prev, { type: currentSelectType, value: curerntSelectText }]
            })}
          }>{"　+　"}</Button>
          <div style={{ display: "flex", flexDirection: "column",  borderWidth: 1, borderColor: "black", borderStyle: "outset" }}>
          {
            previewContents.map((item, index) => (
              <div style={{ display: "flex", justifyContent: "flex-end", gap:10 }}>
                <span>{item.type}</span>
              <Button onClick={() => setCurerntContents(prev => prev.filter((_,i) => i != index))} variant="outlined">×</Button>
              </div>
            ))
          }
          </div>
            <Button variant="outlined" onClick={() => setIsOpenContents(true)}>プレビューを確認する</Button>
        </div>
      <Modal isOpen={isOpenContents} onClose={() => setIsOpenContents(false)}>
        <NewsList>
          <NewsTemplate news={{
            uid: "",
            datetime: "",
            contents: previewContents
          }}/>
        </NewsList>
        {previewContents.length ? <div style={{borderWidth: 1, borderColor: "black", borderStyle: "outset" }}></div> : null }
        <Article contents={previewContents}/>
        {previewContents.length ? <div style={{ display:"flex", justifyContent: "center" }}><Button variant="contained" onClick={handleCreateNews}>この内容で保存する</Button> </div> : null}
      </Modal>
    </>
  )
}
