import React, { useMemo } from "react"
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import { News } from "../../crud/news/create/type";
type Props = {
  news: News
}

export const Template = (props: Props) => {
  const datetime = useMemo(() => {
    const year = props.news.datetime.substring(0, 4);
    const month = props.news.datetime.substring(4, 6);
    const day = props.news.datetime.substring(6, 8);
    return `${year}年${month}月${day}日`
  }, [props.news.datetime])

  return (
    <NavLink to={`/news/${props.news.uid}`}>
      <div className={styles.newsItem}>
          <div style={{ display: "flex" }}>
            <h3 className={styles.newsDate}>{datetime}</h3>
          </div>
          <p className={styles.newsSummary}>{props.news.contents.map(item => {
            if(item.type === "text" || item.type === "header") return item.value
            return ""
          }).filter(item => item !== "").join("").substring(0, 60)}</p>
          <div className={styles.line}/>
      </div>
    </NavLink>
  )
}