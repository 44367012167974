import React, { useMemo } from "react"
import styles from "./style.module.css";
import { Achievement } from "../../crud/achievement/create/type";
type Props = {
  achievement: Achievement
}

export const Template = (props: Props) => {
  const datetime = useMemo(() => {
    const year = props.achievement.datetime.substring(0, 4);
    const month = props.achievement.datetime.substring(4, 6);
    const day = props.achievement.datetime.substring(6, 8);
    return `${year}年${month}月${day}日`
  }, [props.achievement.datetime])

  return (
     <div className={styles.item}>
           <div className={styles.colorBand}></div> {/* 色帯を追加 */}
      <div className={`${styles.section} ${styles.title}`}>{props.achievement.title}</div>
      <div className={styles.section}>支援企業: {props.achievement.client}</div>
      <div className={styles.section}>対象国: {props.achievement.country}</div>
      <div className={styles.section}>実施内容: {props.achievement.about}</div>
    </div>
  )
}