import React, { useState } from "react"
import styles from "./style.module.css";
import { HeaderImage } from "../../share";

const items = [
  { type: "company", label: "会社名", value: ["株式会社Historie"] },
  { type: "start", label: "設立", value: ["2024年2月"] },
  { type: "name", label: "代表者", value: ["常盤 僚平​"] },
  { type: "about", label: "事業概要", value: [
      "1. 海外進出支援事業",
      "2. 業務改革・ コスト削減支援事業",
      "3. サービス開発支援事業",
      "4. webマーケティング支援事業"
    ]
  },
  { type: "bank", label: "取扱銀行", value: ["三井住友銀行​"]},
  { type: "address", label: "本社住所​", value: ["〒135-0046​\n東京都江東区​牡丹3丁目32-10​"]},
] as const;

export const Company = () => {
  return (
    <>
    <HeaderImage src={`${process.env.PUBLIC_URL}/images/image11.jpeg`} titleEn={"Company"} titleJp={"会社概要"} />
    <div className={[styles.container].join(" ")}>
      {items.map((item, index) => (
        <div key={index} className={styles.item}>
          <div className={styles.labelValueContainer}>
            <div className={styles.label}>{item.label}</div>
            {item.type === "about" ? (
              <div className={styles.businessDetails}>
                {item.value.map((detail, index) => (
                  <div key={index} className={styles.value}>{detail}</div>
                ))}
              </div>
            ) : (
              item.type === "address" ? (
                <div className={styles.mapDetails}>
                  <div className={styles.value}>{item.value}</div>
                  <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.347193178826!2d139.79823921127675!3d35.66845217247708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018890ed9a7b7ed%3A0xce854c65362a3b85!2z44CSMTM1LTAwNDYg5p2x5Lqs6YO95rGf5p2x5Yy654mh5Li577yT5LiB55uu77yT77yS4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1707724331151!5m2!1sja!2sjp" width="600" height="450" style={{ border:0 }} loading="lazy"></iframe>
                </div>
              ) : <div className={styles.value}>{item.value}</div>
            )}
          </div>
        </div>
      ))}
    </div>
    </>
  )
}