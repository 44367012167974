import React, { useState, useEffect, ReactNode } from "react"
import styles from "./style.module.css";
import { HeaderImage } from "../../share";
import { Member } from "../../crud/member/create/type";
import { Template } from "./template";
import { Template as Article } from "../../share/article";
import { Modal } from "../../share/modal";
import { useCustomQuery } from "../../hooks";

type Props = {
  children?: ReactNode
}
export const Members = (props: Props) => {
  const members = useCustomQuery('members', async () => {
    const res = await fetch('https://v4ikt55t53.execute-api.ap-northeast-1.amazonaws.com/members');
    const data = await res.json();
    return data.body as Member[];
  }, [])

  const [isOpen, setIsOpen] = useState(false)
  const [member, setMember] = useState<Member | null>(null)

  return (
    <>
    <HeaderImage src={`${process.env.PUBLIC_URL}/images/image10.png`} titleEn={"Team"} titleJp={"メンバー紹介"}/> 
    <div className={[styles.contents].join(" ")}>
      <Article contents={[{ type: "header", value: "メンバー紹介" }]} />
      <div className={styles.profileList}>
        {members.map(member => {
          return (
            <Template member={member} onClick={() => {
              setMember(member)
              setIsOpen(true)
            }}/>
          )
        })}
        {props.children}
      </div>
    </div>
    {member? <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Article contents={member.contents}/>
      </Modal> :null}
    </>
  )
}