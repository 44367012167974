import React, { useState, useMemo } from "react";
import { CreateMember } from "../../crud/member/create"
import { CreateNews } from "../../crud/news/create";
import { CreateAchievement } from "../../crud/achievement/create";

const options = [
  { type: "member", component: <CreateMember /> },
  { type: "news", component: <CreateNews /> },
  { type: "achievement", component: <CreateAchievement /> }
] as const

type OptionType = typeof options[number]['type']

export const Admin = () => {
  const [tab, setTab] = useState<OptionType>("member")
  const component = useMemo(() => {
    return options.find(option => option.type === tab)?.component
  }, [tab])

  
  return (
    <>
      <select onChange={(e) => setTab(e.target.value as OptionType)} value={tab}>
        {options.map(option => (
          <option>
            {option.type}
          </option>
        ))}
      </select>
      <br/>
      {component}
    </>
  )
}