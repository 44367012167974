import React, { ReactNode } from "react"
import { News } from "../../crud/news/create/type";
import { useCustomQuery } from "../../hooks";
import { Template } from "./template";
import styles from "./style.module.css";
import { Achievement } from "../../crud/achievement/create/type";
import { HeaderImage } from "../../share";
import { Template as ArticleTemplate } from "../../share/article";
type Props = {
  children?: ReactNode
}

export const AchievementList = (props: Props) => {
  const achievements = useCustomQuery<Achievement[]>("achievements", async () => {
    const res = await fetch('https://v4ikt55t53.execute-api.ap-northeast-1.amazonaws.com/achievements')
    const data = await res.json();
    return data.body
  }, [])

  return (
    <>
    <HeaderImage src={`${process.env.PUBLIC_URL}/images/image17.png`} titleEn={"Experience"} titleJp={"支援実績"} />

    <div className={styles.contents}>
    <ArticleTemplate contents={[{ type: "header", value: "支援実績" }]}/>

    <div className={styles.grid}>
    {achievements.map(item => <Template achievement={item}/>)}
    {props.children}
    </div>
    </div>
  </>
  )
}