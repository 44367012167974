import React from "react"
import { News } from "../../crud/news/create/type";
import { useCustomQuery } from "../../hooks";
import { useParams } from "react-router-dom";
import { Template } from "../../share/article";

export const NewsPage = () => {
  let { id } = useParams();
  const news = useCustomQuery<News | null>(`news/${id}`, async () => {
    const res = await fetch(`https://v4ikt55t53.execute-api.ap-northeast-1.amazonaws.com/news/${id}`)
    const data = await res.json();
    return data.body
  }, null)

  return (
    <>
      { news ? <Template contents={news.contents}/>:null }
    </>
  )
}