import React from "react"
import styles from "./style.module.css";
import { Footer, HeaderImage } from "../../share";

type Contnt = ({
  type: "mainHeader",
  value: { title: string, subTitle: string }
} | {
  type: "text"
  value: string
} | {
  type: "image"
  value: string
} | {
  type: "br"
})

export const Consulting = () => {
  const contents: Contnt[] = [
    { type: "mainHeader", value: { title: "Consulting", subTitle: "海外進出支援事業" } },
    { type: "text", value: "VUCA時代と言われる昨今、社会状況や生活者トレンドも日々目まぐるしいスピードで変化をし続けています。​そして人口の減少傾向にある日本において事業を未来につなげ発展し続けるために、日系企業は海外に目を向けていかなければなりません。" },
    { type: "text", value: "​世界第一位の人口を誇るインドを初めとした世界各国にわたる現地パートナーを活用し、市場参入において必要なプロセスを一気通貫でご提供しています。そして海外進出に関連するアドバイザリーだけではなく、現地ネットワークを通じて進出国での事業展開をご支援いたします。​" },
    { type: "text", value: "特に、ITやコンテンツの海外進出支援に注力しており、現地消費者のニーズや商習慣を把握したうえで、​コンテンツのローカライズ、マーケティング支援を行います。​" },
    { type: "image", value: `${process.env.PUBLIC_URL}/images/image5.png` },
    { type: "br" },
    { type: "mainHeader", value: { title: "Re-engineering", subTitle: "業務改革・ コスト削減支援" } },
    { type: "text", value: "生成AIの出現により人の手が必要な業務の範囲は大きく変わりました。ですが、多くの企業はそのような新しいテクノロジーを活用し業務を効率化できる人材がいない状況にあります。近年の賃金高騰において、業務の効率化は企業にとって急務の課題となっています。限られた経営資源（ヒト・モノ・カネ・情報）を有効に活用し、利益を最大化するためには、業務の自動化とコストの最小化を併せて実現する必要があります。​" },
    { type: "text", value: "ITのバックグラウンドを持つ私たちがアウトサイダーとして、企業における業務改革をご支援いたします。​" },
    { type: "image", value: `${process.env.PUBLIC_URL}/images/image9.png` },
  ]
  return (
    <>
    <HeaderImage src={`${process.env.PUBLIC_URL}/images/image6.png`} titleEn={"Services"} titleJp={"事業紹介"}/>
    <div className={[styles.contents].join(" ")}>
      {contents.map(content => {
        if(content.type === "mainHeader") return (
          <div className={styles.mainHeader}>
            <h2>{content.value.title}</h2>
            <h3>{content.value.subTitle}</h3>
          </div>
        )
        if(content.type === "text") return (
          <p>
            {content.value}
          </p>
        )
        if(content.type === "image") return <img src={content.value} style={{ width: "100%" }}/>
        if(content.type === "br") return <br/>
      })}
    </div>
    </>
  )
}