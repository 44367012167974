import React from "react"
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import { NewsList } from "../news";
import { HeaderImage } from "../../share";

export const Home = () => {
  const contents = [
    { title: "Services", subTitle: "事業紹介", url : `${process.env.PUBLIC_URL}/images/image1.png`, href: "/consulting" },
    { title: "Team", subTitle: "プロフェッショナル紹介",url: `${process.env.PUBLIC_URL}/images/image2.png`, href: "/members" },
    { title: "Experience", subTitle: "支援実績",url: `${process.env.PUBLIC_URL}/images/image3.png`, href: "/achievements" },
    { title: "Company", subTitle: "会社概要",url: `${process.env.PUBLIC_URL}/images/image4.png`, href: "/company" },
  ]

    return (
      <>
      <div className={styles.headerImageBackground} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/image12.png)` }}>
        <div className={styles.descriptionBackground}>
          <p className={styles.haederTitleEn}>Together, Forge a new Path for the Growth</p>
          <p className={styles.headerSubJp}>過去から現在、そして未来へ​<br/>新しい市場の創出・事業拡大を支援します</p>
        </div>
      </div>
      <NewsList />
      <div className={styles.contentDescription}>企業情報</div>
      <div className={styles.imageTextWrapper}>
        {
          contents.map(content => (
            <NavLink to={content.href} className={styles.imageTextContainer}>
              <img src={content.url} className={styles.responsiveImage} />
              <div className={styles.imageText}>
                <p className={styles["imageText-enp"]}>{content.title}</p>
                <p>{content.subTitle}</p>
              </div>
            </NavLink>
          ))
        }
    </div>
    <div className={styles.contentDescription}>
      <p>お問い合わせ</p>
      <p>CONTACT</p>
    </div>
    <div className={styles.contentDescriptionThin}>
    ご質問やご相談はお気軽にお問い合わせください。​
    </div>
    <div style={{
      width: "100%",
      display: "flex",
      justifyContent: "center"
    }}>
    <button className={styles.toContact}>
      <NavLink to="/contact" preventScrollReset={true}>
        お問い合わせはこちら
      </NavLink>
    </button>
    </div>
    
      </>
  )
}