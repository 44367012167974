import React, { useState, useEffect } from "react";
import styles from "./style.module.css";
import { NavLink } from "react-router-dom";

const items = [
    { label: "事業紹介", href: "/consulting" },
    { label: "プロフェッショナル紹介", href: "/members" },
    { label: "支援実績", href: "/achievements" },
    { label: "会社概要", href: "/company" },
    { label: "お問い合わせ", href: "/contact" }
]
type Props = {
  src: string;
  titleEn: string;
  titleJp: string;
}
export const Header = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 848) {
          setIsNavVisible(false);
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      // コンポーネントのアンマウント時にイベントリスナーを削除
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
      };
    
  const onClose = () => {
    setIsNavVisible(false)
  }
  return (
    <>
     <div className={styles.fixed}>
      <header className={styles.header}>
        <NavLink className={styles.logo} preventScrollReset={true}  to="/">
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} />
          <span>Historie</span>
        </NavLink>
        <img className={styles.navToggle} src={isNavVisible ? `${process.env.PUBLIC_URL}/images/ng.png` : `${process.env.PUBLIC_URL}/images/hamburger.png`} onClick={toggleNav}/>
        {isNavVisible ? null : <Navigation isNavVisible={isNavVisible} onClose={onClose}/>}
      </header>
      {isNavVisible ? <Navigation isNavVisible={isNavVisible} onClose={onClose}/> : null}
      </div>
      <div className={styles.headerPadding}></div>
    </>
  )
}

const Navigation = (props: { isNavVisible: boolean, onClose: () => void }) => {
  return (
    <nav className={props.isNavVisible ? `${styles.nav} ${styles.navVisible}` : styles.nav }>
      <ul className={styles.navList}>
          {items.map((item, index) => (
            <li key={index} className={[styles.navItem].concat(window.location.pathname === item.href ? [styles.select] : []).join(" ")}>
              <NavLink to={item.href} preventScrollReset={false} onClick={props.onClose}>
                <div className={[styles.navLink].concat(window.location.pathname == item.href ? styles["selected"] : []).join(" ")}>
                  <span>{item.label}</span>
                </div>
              </NavLink>
            </li>
        ))}
      </ul>
    </nav>
  )
}

export const HeaderImage = (props: Props) => {
  return (
    <>
      <div className={styles.headerImageBackground} style={{ backgroundImage: `url(${props.src})` }}>
        <div className={styles.descriptionBackground}>
          <p className={styles.haederTitleEn}>{props.titleEn}</p>
          <p className={styles.headerSubJp}>{props.titleJp}</p>
        </div>
      </div>
    </>
  )
}