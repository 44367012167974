import React, { ReactNode } from "react"
import { News } from "../../crud/news/create/type";
import { useCustomQuery } from "../../hooks";
import { Template } from "./template";
import styles from "./style.module.css";
type Props = {
  children?: ReactNode
}

export const NewsList = (props: Props) => {
  const news = useCustomQuery<News[]>("news", async () => {
    const res = await fetch('https://v4ikt55t53.execute-api.ap-northeast-1.amazonaws.com/news')
    const data = await res.json();
    return data.body
  }, [])

  console.log(news)
  return (
    <>
    <div className={[styles.container].join(" ")}>
      <div className={styles.newsSidebar}>
          <h2 className={styles.newsTitleJp}>ニュース</h2>
          <h2 className={styles.newsTitleEn}>News</h2>
      </div>
      <div className={styles.newsContent}>
      {news.map(item => <Template news={item}/>)}
        {props.children}
    </div>
    </div>
  </>
  )
}